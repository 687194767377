import { render, staticRenderFns } from "./HistoryEditor.vue?vue&type=template&id=2152e349&scoped=true"
import script from "./HistoryEditor.vue?vue&type=script&lang=js"
export * from "./HistoryEditor.vue?vue&type=script&lang=js"
import style0 from "./HistoryEditor.vue?vue&type=style&index=0&id=2152e349&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2152e349",
  null
  
)

export default component.exports