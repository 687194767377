<template>
  <div class="no-padding">
    <b-row>
      <b-col><h5>{{ $t('HISTORY.DETAILS') }}</h5></b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          label-size="sm"
          label-cols="4"
          content-cols="6"
          :label="$t('HISTORY.CREATED_AT')">
          <b-form-input :disabled="true" size="sm" v-model="local_history.created_at"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          label-size="sm"
          label-cols="4"
          content-cols="6"
          :label="$t('HISTORY.TYPE')">
          <b-form-input :disabled="true" size="sm" v-model="translated_type"/>
        </b-form-group>
      </b-col>
    </b-row>


    <b-row>
      <b-col cols="12">
        <b-form-group
          label-size="sm"
          label-cols="4"
          content-cols="6"
          :label="$t('HISTORY.GROUP')">
          <b-form-input :disabled="true" size="sm" v-model="translated_group"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">

        <div class="table-container">
          <b-table
            id="data-table"
            class="table-striped"
            :fields="fields"
            :items="items"
            head-variant="light"
            ref="data-table"
          >

          </b-table>

          <p class="ml-4" v-if="items.length === 0">{{$t('HISTORY.NO_DATA_FOUND')}}</p>

        </div>

      </b-col>
    </b-row>


  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {

  name: 'HistoryEditor',

  props: ['history'],

  mixins: [ toasts ],

  components: {

  },

  watch: {
    history: {
      deep: true,
      handler(val) {
        this.local_history = { ...val };
      },
    }
  },

  computed: {
    translated_group() {
      return this.$t('HISTORY.' + this.local_history?.group?.toUpperCase());
    },
    translated_type() {
      return this.$t('HISTORY.HISTORY_TYPE.' + this.local_history?.type?.toUpperCase());
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'companies', 'periods', 'profile', 'settings','isTHS']),
    items() {

      if (!this.local_history.data || !this.local_history.data.changed_values || !this.local_history.data.changed_values.length === 0) {
        return [];
      }

      const rows = [];

      for (const old in this.local_history.data.old_values) {
        const new_value = this.local_history.data.changed_values[old];
        const old_value = this.local_history.data.old_values[old];

        if (!this.is_equivalent(new_value, old_value)) {
          rows.push({
            field: this.$t('MEMBER.' + old.toUpperCase()),
            old_value: old_value,
            new_value: new_value
          });
        }
      }

      return rows;
    },
    fields() {
      return [
        {
          key: 'field',
          label: this.$t('HISTORY.FIELD'),
          tdClass: 'td-overflow'
        },
        {
          key: 'old_value',
          label: this.$t('HISTORY.OLD_VALUE'),
          tdClass: 'td-overflow'
        },
        {
          key: 'new_value',
          label: this.$t('HISTORY.NEW_VALUE'),
          tdClass: 'td-overflow'
        },
      ];
    }
  },

  methods: {

    null_to_empty(val) {
      if (val === null || val === undefined || val === '' || val === 'null') {
        return '';
      }

      return val;
    },

    is_equivalent(val1, val2) {
      if (this.null_to_empty(val1) === this.null_to_empty(val2)) {
        return true;
      }

      if (val1+'' === val2+'') {
        return true;
      }

      return false;
    }
  },

  mounted() {
    if (this.history) {
      this.local_history = { ...this.history }
    }
  },

  data() {
    return {
      local_history: {}
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
