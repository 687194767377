<template>
  <div class="no-padding">
    <b-modal
      ref="edit-form"
      hide-footer
      size="lg"
      >
      <HistoryEditor
        :history="history"
      />

    </b-modal>
  </div>
</template>


<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';

import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import HistoryEditor from './HistoryEditor.vue';


export default {

  name: 'HistoryEditorModal',

  props: ['history'],

  mixins: [ toasts ],

  components: {
    RequiredFieldsInfo,
    HistoryEditor
  },

  watch: {

  },

  computed: {
    ...mapGetters([ ]),

  },


  mounted() {

  },

  methods: {
    show() {
      this.$refs['edit-form'].show();
    },
    hide() {
      this.$refs['edit-form'].hide();
    }

  },

  data() {
    return {


    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
