<template>
  <div class="container">

    <b-row>

      <b-col cols="12" >
        <b-form-group
          label-size="sm"
          label-cols="4"
          content-cols="6"
          :label="$t('MEMBER.MEMBER_ID')">
          <b-form-input
            :disabled="true"
            size="sm"
            v-model="member.member_id"/>
        </b-form-group>
      </b-col>

    </b-row>

    <b-row>

      <b-col cols="12" >
        <b-form-group
          label-size="sm"
          label-cols="4"
          content-cols="6"
          :label="$t('MEMBER.EMAIL')">
          <b-form-input
            :disabled="true"
            size="sm"
            v-model="member.email"/>
        </b-form-group>
      </b-col>

    </b-row>

    <b-row>

      <b-col cols="12" >
        <b-form-group
          label-size="sm"
          label-cols="4"
          content-cols="6"
          :label="$t('MEMBER.FIRSTNAME')">
          <b-form-input
            :disabled="true"
            size="sm"
            v-model="member.firstname"/>
        </b-form-group>
      </b-col>

    </b-row>

    <b-row>

    <b-col cols="12" >
      <b-form-group
        label-size="sm"
        label-cols="4"
        content-cols="6"
        :label="$t('MEMBER.LASTNAME')">
        <b-form-input
          :disabled="true"
          size="sm"
          v-model="member.lastname"/>
      </b-form-group>
    </b-col>

  </b-row>


  </div>

</template>

<style lang="css" scoped>

.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  padding: 4px !important;
}

.row {
  margin-left: 0px !important;
}

.col-form-label {
  margin-top: 6px !important;
}

.top-row {
  margin-bottom: 16px;
}

.icon-container {
  width: 50px;
}

.container {
  padding-bottom: 48px;
  flex: 1 0 auto;
}

.form-group {
  margin-bottom: 0px !important;
}


</style>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {

  name: 'MemberDetails',

  props: ['member_id'],

  mixins: [ toasts ],

  components: {

  },

  watch: {
    member_id() {
      this.get_member();
    }
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentMemberId', 'companies', 'periods', 'profile', 'settings','isTHS']),
  },

  methods: {

    async get_member() {
      try {
        const response = await axios.get(`/member/${this.member_id}`);
        this.member = response.data.member;
      } catch (error) {
        console.error(error);
      }
    }

  },

  mounted() {
    this.get_member();
  },

  data() {
    return {
      member: {}
    };
  }
};

</script>
