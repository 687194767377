<template>
  <div class="no-padding">


    <ExpandableTable
      :children="permissions_list"
      :fields="permissions_fields"
      highlightable
      @cell_value_changed="checkbox_changed"
    />



  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import ExpandableTable from '@/view/components/tables/ExpandableTable.vue';

export default {

  name: 'HistorySettings',

  mixins: [ toasts ],

  components: {
    ExpandableTable
  },

  watch: {

  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'companies', 'periods', 'profile', 'settings','isTHS']),
    permissions_fields() {
      return [
        { name: 'name', text: this.$t('HISTORY.NAME'), cols: 2 },
        { name: 'value', text: this.$t('HISTORY.ACTIVATE'), cols: 2, type: 'checkbox' }
      ];
    }
  },

  methods: {

    checkbox_changed(value) {
      console.log('checkbox changed', value);

      if (value.item.group === 'MEMBER') {
        this.history_settings.members[value.item.key] = value.value;
      }
      else if (value.item.group === 'USER') {
        this.history_settings.users[value.item.key] = value.value;
      }

      this.put_history_settings();
    },

    async put_history_settings() {

      try {
        const res = await axios.put(`/company/company_settings/${this.currentCompanyId}`, {
          history: this.history_settings
        });

        if (res.status === 200) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('HISTORY.HISTORY_SETTINGS_UPDATED'));
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('HISTORY.UNABLE_UPDATE_HISTORY_SETTINGS'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('HISTORY.UNABLE_UPDATE_HISTORY_SETTINGS'));
      }

    },

    async get_history_settings() {
      try {
        const res = await axios.get(`/company/${this.currentCompanyId}`);
        if (res.status === 200) {

          this.set_table_data(res.data.company_settings.history);

          console.log('settings assigned', this.history_settings);
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('HISTORY.UNABLE_LOAD_HISTORY_SETTINGS'));
      }

    },

    set_table_data(settings) {

      this.permissions_list[0].value = undefined;
      this.permissions_list[1].value = undefined;
      
      for (const child of this.permissions_list[0].children) {
        child.value = settings.members[child.key];
      }

      for (const child of this.permissions_list[1].children) {
        child.value = settings.users[child.key];
      }

      this.permissions_list = [...this.permissions_list];

      this.history_settings = settings;
    },


  },

  mounted() {
    this.get_history_settings();
  },

  data() {
    return {

      history_settings: {
        members: {
          create: false,
          update: false,
          delete: false,
          read: false
        },
        users: {
          create: false,
          update: false,
          delete: false,
          login: false
        }
      },

      permissions_list: [
        {
          name: this.$t('HISTORY.MEMBER'),
          group: 'MEMBER',
          value: false,
          children: [
            {
              name: this.$t('HISTORY.CREATE'),
              group: 'MEMBER',
              key: 'create',
              value: false,
            },
            {
              name: this.$t('HISTORY.UPDATE'),
              group: 'MEMBER',
              key: 'update',
              value: false,
            },
            {
              name: this.$t('HISTORY.DELETE'),
              group: 'MEMBER',
              key: 'delete',
              value: false,
            },
            {
              name: this.$t('HISTORY.VIEW'),
              group: 'MEMBER',
              key: 'view',
              value: false,
            },
          ]
        },
        {
          name: this.$t('HISTORY.USER'),
          key: 'users',
          group: 'USER',
          val: false,
          children: [
            {
              name: this.$t('HISTORY.CREATE'),
              group: 'USER',
              key: 'create',
              value: false,
            },
            {
              name: this.$t('HISTORY.UPDATE'),
              group: 'USER',
              key: 'update',
              value: false,
            },
            {
              name: this.$t('HISTORY.DELETE'),
              group: 'USER',
              key: 'delete',
              value: false,
            },
            {
              name: this.$t('HISTORY.LOGIN'),
              group: 'USER',
              key: 'login',
              value: false,
            },
          ]
        }
      ],


    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
